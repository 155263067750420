import { MainTemplate2 } from '../../templates/MainTemplate2';
import { StorageUploadForm } from "./StorageUploadForm";
import { Stack } from "@mui/material";

export type StorageUploadFormProps = {
    storageId: string;
    title: string;
}

// 監視プログラムのアップロード画面
export const StorageUpload = () => {

    // 権限チェックが必要

    return (
        <MainTemplate2>
            <Stack spacing={10}>
                <StorageUploadForm title="サーバー監視プログラムのアップロード" storageId="1" />
                <StorageUploadForm title="Updateプログラムのアップロード" storageId="2" />
                <StorageUploadForm title="バックアップUploadプログラムのアップロード" storageId="3" />
            </Stack>
        </MainTemplate2>
    );
};
